import {Button, Popover} from 'antd';
import React, {FC, useEffect, useState} from 'react';
import {Tags} from './Tags';
import {TagItem} from './TagItem';
import {Category} from '../common/api';
import styled from 'styled-components';

interface TopicsProps {
  onSelectItem: (value: Category[]) => void;
  data: Category[];
  canSelect?: number;
  fullWidth?: boolean;
  selectedItems?: any[];
  title?: string;
}

const ContentWrapper = styled.div`
  max-width: 405px;
  @media (max-width: 768px) {
    max-width: 300px;
  }
`;

export const Topics: FC<TopicsProps> = ({
                                          fullWidth,
                                          onSelectItem,
                                          canSelect = 5,
                                          data,
                                          title = 'Filter By Category',
                                          selectedItems
                                        }) => {
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState<Category[]>([]);

  useEffect(() => {
    if (!selectedItems) return;
    setItems(selectedItems);
  }, [selectedItems]);


  function onSelect(tag: any): void {
    let currentItems;
    if (canSelect === 1) {
      currentItems = items?.[0]?.id !== tag.id ? [tag] : [];
      setOpen(false);
    } else {
      const filteredItems = items.filter((item) => item.id !== tag.id);
      currentItems = items?.length !== filteredItems?.length ? filteredItems : items.length < canSelect ? [...items, tag] : null;
      if (!currentItems) return;
    }
    setItems(currentItems);
    onSelectItem(currentItems);
  }

  return (
    <Popover
      content={
        <ContentWrapper>
          {!!data?.length ? (
            <Tags>
              {data?.map((item) => (
                <div key={item.id} style={{
                  display: 'flex',
                  opacity: `${items.find((i) => i.id === item.id) ? '0.5' : '1'}`
                }}>
                  <TagItem tag={item.name} onSelect={() => onSelect(item)}/>
                </div>))}
            </Tags>
          ) : (
            <div className="p-2">Empty</div>
          )
          }
        </ContentWrapper>
      }
      placement="bottomRight"
      trigger="click"
      open={open}
      onOpenChange={() => setOpen(!open)}
    >
      <Button
        className={`color-black-0 fw-m ${fullWidth ? 'w-full' : ''}`} style={{fontSize: '14px'}}
        type="default"> {title}</Button>
    </Popover>

  );
};
