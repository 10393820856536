import styled from 'styled-components';
import React, {CSSProperties, FC, ReactNode} from 'react';

const TagsWrapper = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const WrapperContainer: CSSProperties = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '8px',
  maxHeight: '300px',
  overflowY: 'auto'
};
const ScrollableContainer: CSSProperties = {
  width: '100%',
  height: '100%',
  display: 'flex',
  overflowX: 'auto',
  gap: '8px',
};

interface TagsProps {
  children: ReactNode;
  inline?: boolean;
}

export const Tags: FC<TagsProps> = ({inline = false, children}) => {
  return (
    <TagsWrapper>
      <div className="invisible-scrollbar" style={inline ? ScrollableContainer : WrapperContainer}>
        {children}
      </div>
    </TagsWrapper>
  );
};
