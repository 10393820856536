export function toQueryString(obj: { [key: string]: any }): string {
  const keys = {
    search: 'search',
    tag: 'tag',
    category: 'category',
    page: 'page.index',
    size: 'page.size',
    sort: 'sort.key',
    order: 'sort.order',
    type: 'type',
    tool: 'tool',
  };
  const str = [];
  for (const p in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(p) && obj[p] != null) {
      // @ts-ignore
      str.push(keys[encodeURIComponent(p)] + '=' + encodeURIComponent(obj[p]));
    }
  }
  return str.join('&');
}
