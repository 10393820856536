export function calculateDaysAgo(date: number): string {
  if (!date) return '';
  const today: number = Date.now(); // Current date
  const givenDate: Date = new Date(date); // Given date

  // Calculate the difference in milliseconds
  const differenceInTime: number = today - givenDate.getTime();
  const daysCount = Math.round(differenceInTime / (1000 * 3600 * 24));
  if (daysCount) {
    return `${daysCount} day${daysCount > 1 ? 's' : ''} ago`;
  }
  return 'Today';
}
