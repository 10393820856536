import {Avatar, Button} from 'antd';
import {LogoutOutlined, UserOutlined} from '@ant-design/icons';
import {logout} from '../firebase';
import React, {FC, useRef, useState} from 'react';
import {useOutsideAlerter} from '../common/hooks/useOutsideClick';
import {FirebaseUser} from '../common/api';
import {useNavigate} from 'react-router-dom';

interface UserFrameProps {
  user: FirebaseUser;
}

export const UserFrame: FC<UserFrameProps> = ({user}) => {
  const [open, setOpen] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useOutsideAlerter(elementRef, () => setOpen(false));
  return (
    <div ref={elementRef}>
      <Avatar className="pointer ml-2"
              src={user?.photoURL ?? ''}
              icon={<UserOutlined/>}
              onClick={() => setOpen(!open)}
      />
      {open && (
        <div style={{backgroundColor: 'white', borderRadius: '4px'}}
             className="absolute direction-column flex top-10 right-0 p-6 box-shadow border">
          <div className="mb-2"><b>Name:</b> <span className="fw-m">{user.displayName}</span></div>
          <div className="mb-4"><b>Email:</b> <span className="fw-m">{user.email}</span></div>
          <div className="flex items-center justify-between">
            <Button
              shape="round"
              className="mr-4"
              onClick={() => {
                setOpen(false);
                navigate('/bookmarks');
              }
              }
            >
              🌟 My Saved Prompts
            </Button>

            <Button className="ml-auto bg-black"
                    size="middle"
                    shape="round"
                    type="primary"
                    onClick={logout}
                    icon={<LogoutOutlined/>}
            >
              Log out
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
