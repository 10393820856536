import {atom} from 'jotai';
import {Category, FirebaseUser, Tool} from '../api';

export interface Filter {
  tag: string | null,
  category: Category | null,
  tool: Tool | null,
}

export const defaultFilter = {
  tag: null,
  category: null,
  tool: null
};

export const promptFilter = atom<Filter>(defaultFilter);

export const promptCount = atom<number | null>(null);

export const userSignIn = atom<boolean>(false);

export const shouldReceiveNewsletters = atom<boolean>(true);

export const userProfile = atom<FirebaseUser | null>(null);
