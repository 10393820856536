import React, {FC, ReactNode} from 'react';
import {Checkbox, Modal} from 'antd';
import {signIn} from '../firebase';
import styled from 'styled-components';
import {useAtom} from 'jotai';
import {shouldReceiveNewsletters} from '../common/utils/atoms';

enum Provider {
  Google = 'google',
  Facebook = 'facebook',
}

const ProviderButton = styled.button`
  align-items: center;
  margin: auto;
  width: 100%;
  display: flex;
  border-radius: 4px;
  color: var(--white);
  font-size: 15px;
  font-weight: 500;
  height: 40px;
  min-width: 120px;
  padding: 10px;
  border: none;
  cursor: pointer;

  span {
    color: white;
    border: none;
    font-weight: 500;
    font-size: 15px;
    margin-left: 10px;
  }

  &:hover, &:active {
    opacity: 0.7;
  }
`;

interface FirebaseModalProps {
  open: boolean,
  setOpen: (open: boolean) => void
  children?: ReactNode,
}

export const FirebaseModal: FC<FirebaseModalProps> = (
    {
      children,
      open = false,
      setOpen
    }
  ) => {

    const [checked, setChecked] = useAtom(shouldReceiveNewsletters);
    return (
      <>
        {children}
        <Modal
          title="Sign In"
          footer={null}
          centered
          onCancel={() => setOpen(false)}
          width={320}
          style={{padding: 0}}
          open={open}
        >
          <div className="pt-2">
            <div className="mb-4">
              <ProviderButton style={{backgroundColor: '#2f81ec'}} onClick={() => {
                signIn(Provider.Facebook);
                setOpen(false);
              }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1365.3 1365.3" width="25" height="22">
                  <path
                    d="M1365.3 682.7A682.7 682.7 0 10576 1357V880H402.7V682.7H576V532.3c0-171.1 102-265.6 257.9-265.6 74.6 0 152.8 13.3 152.8 13.3v168h-86.1c-84.8 0-111.3 52.6-111.3 106.6v128h189.4L948.4 880h-159v477a682.8 682.8 0 00576-674.3"
                    fill="#fff"/>
                </svg>
                <span>Sign in with Facebook</span>
              </ProviderButton>
            </div>

            <div>
              <ProviderButton style={{backgroundColor: '#dd4e31'}} onClick={() => {
                signIn(Provider.Google);
                setOpen(false);
              }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" viewBox="0 0 25 22" fill="none">
                  <path
                    d="M17.1429 6.02002C15.9152 4.93002 14.3536 4.37502 12.6144 4.37502C9.53047 4.37502 6.91995 6.30816 5.98724 8.90628V8.91003C5.75032 9.57004 5.6157 10.275 5.6157 11C5.6157 11.725 5.75032 12.43 5.98724 13.09L5.98619 13.0908C6.91799 15.6904 9.52931 17.625 12.6144 17.625C14.2082 17.625 15.5652 17.235 16.6259 16.575L16.6273 16.576C17.8819 15.7959 18.7165 14.63 18.9911 13.255H12.6157V9H23.7727C23.9127 9.72001 23.9881 10.47 23.9881 11.25C23.9881 14.6 22.6958 17.42 20.4557 19.335H20.4543C18.4943 21.015 15.8128 22 12.6144 22C7.98359 22 3.97743 19.535 2.02819 15.94V15.9376C1.22671 14.4532 0.769531 12.7741 0.769531 11C0.769531 9.22531 1.22708 7.54555 2.02916 6.06072L2.02819 6.06002C3.97743 2.46501 7.98359 0 12.6144 0C15.8075 0 18.489 1.09 20.5406 2.86501L17.1429 6.02002Z"
                    fill="white"/>
                </svg>
                <span>Sign in with Google</span>
              </ProviderButton>
            </div>
            <Checkbox className="mt-2"
                      onChange={() => setChecked(!checked)}
                      checked={checked}>
              <span style={{ fontSize: '12px', fontWeight: 500}}>
                I wish to receive a weekly newsletter with the hottest prompts and AI learnings.
              </span>
            </Checkbox>
          </div>
        </Modal>
      </>
    );
  }
;
