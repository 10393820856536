import {Tags} from './Tags';
import React, {FC, ReactNode} from 'react';
import {Bookmark, Prompt} from '../common/api';
import {TagItem} from './TagItem';
import {Button, Card, Image} from 'antd';
import {BookOutlined, DeleteOutlined} from '@ant-design/icons';
import {calculateDaysAgo} from '../common/utils/helpers';
import {UseItButton} from './UseItButton';
import {useNavigate} from 'react-router-dom';
import {useUserBookmarksMutation} from '../common/hooks/useUser';
import {useQueryClient} from 'react-query';
import {userSignIn} from '../common/utils/atoms';
import {useAtom} from 'jotai';
import {UnSaveButton} from './PromptPage';
import {PromptType} from '../common/hooks/usePrompts';
import {useIsMobile} from '../common/hooks/useIsMobile';
import {Tool, ToolName} from '../common/enums/tool';

interface PromptItemProps {
  prompt: Prompt;
  onTagSelect?: (tag: string) => void;
  bookmarked?: Bookmark;
  authorized?: boolean;
  clickable?: boolean;
  promptText?: boolean;
  children?: ReactNode;
}

export enum BookmarkAction {
  Save = 'save',
  Remove = 'remove'
}

const promptTool: { [key: string]: string } = {
  [Tool.Chatgpt3]: ToolName.Chatgpt3,
  [Tool.DallE]: ToolName.DallE,
  [Tool.Midjourney]: ToolName.Midjourney,
  [Tool.StableDiffusion]: ToolName.StableDiffusion,
};

const open = true;
const mobileImageWidth = 130;
const imageWidth = 200;
export const PromptItem: FC<PromptItemProps> = (
  {
    prompt,
    bookmarked,
    onTagSelect,
    authorized,
    clickable = true,
    promptText = false,
    children
  }
) => {
  const isMobile = useIsMobile();
  const setOpenFirebaseModal = useAtom(userSignIn)[1];
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {createBookmark, removeBookmark} = useUserBookmarksMutation();

  function preventClick(ev: any): void {
    ev.stopPropagation();
    ev.preventDefault();
  }

  function handleBookmark(id: string, action: BookmarkAction): void {
    if (authorized) {
      if (action === BookmarkAction.Remove) {
        removeBookmark(id, {
          onSuccess: () => {
            queryClient.invalidateQueries('user-bookmarks');
          }
        });
      } else {
        createBookmark(id, {
          onSuccess: () => {
            queryClient.invalidateQueries('user-bookmarks');
          }
        });
      }
    } else {
      setOpenFirebaseModal(true);
    }
  }

  return (
    (prompt &&
        <Card title={<>
          {children}
          {prompt.title ?? 'Untitled'}
        </>}
              className="mb-4"
              hoverable={clickable}
              onClick={(ev) => {
                if (clickable) {
                  navigate(`/prompt-details/${prompt.id}`);
                }
                preventClick(ev);
              }}
              bodyStyle={{padding: 0}}
              cover={(
                <div className="p-2">
                  {!!prompt?.tags?.length && <div className="flex flex-wrap gap-2 p-6 pt-0 pb-2">
                      <Tags>
                        {prompt.tags.map((tag: string) => (<TagItem key={tag} tag={tag} onSelect={(tag, ev) => {
                          onTagSelect?.(tag);
                          preventClick(ev);
                        }
                        }/>))}
                      </Tags>
                  </div>}

                  <div className="flex">
                    {clickable && prompt.type === PromptType.Art && !!prompt.preview && (
                      <div className="p-1 pl-4">
                        <Image preview={false}
                               width={isMobile ? mobileImageWidth : imageWidth}
                               src={prompt.preview}
                               style={{padding: 5}}
                        />
                      </div>
                    )}
                    {!!prompt.description && <section className="pl-6 pr-6 pb-2 pt-2">
                        <span className="text-black word-break">{prompt.description}</span>
                    </section>}
                  </div>

                  {promptText && !!prompt.prompt && (
                    <section className="pl-6 pr-6 pl-4">
                      <p className="text-black word-break">
                        {prompt.prompt}
                      </p>
                    </section>
                  )}

                  <div className={`collapse${open ? ' collapse--open' : ''}`}>
                    <div className="collapse-in">

                      <div className="border p-6 pt-2">

                        <div className="flex items-center justify-end mb-4 gap-4">
                          {
                            bookmarked ? (
                              <UnSaveButton
                                icon={<DeleteOutlined onClick={preventClick}/>}
                                size="large"
                                onClick={(ev) => {
                                  ev.stopPropagation();
                                  ev.preventDefault();
                                  handleBookmark(prompt.id, BookmarkAction.Remove);
                                }}
                              >UnSave</UnSaveButton>
                            ) : (
                              <Button
                                icon={<BookOutlined onClick={preventClick}/>}
                                size="large"
                                onClick={(ev) => {
                                  ev.stopPropagation();
                                  ev.preventDefault();
                                  handleBookmark(prompt.id, BookmarkAction.Save);
                                }}
                              >Save</Button>
                            )
                          }
                          <UseItButton promptText={prompt.prompt}
                                       promptId={prompt.id}
                                       authorized={!!authorized}
                                       promptType={prompt.tool}
                                       handleSignIn={() => setOpenFirebaseModal(true)}/>
                        </div>

                        <div className="flex items-center justify-between gap-2">
                          <div className=""
                               style={{fontWeight: 500}}
                               onClick={preventClick}
                          >
                            &nbsp; <BookOutlined
                            onClick={preventClick}/>&nbsp; {prompt.bookmarkedCount} Saved
                          </div>
                          <div>

                            <span className="no-wrap mr-2 fw-m" style={{fontSize: '11px'}}>
                              &nbsp;{calculateDaysAgo(prompt.createdAt)}
                            </span>
                            <span className="no-wrap mr-2 fw-m"
                                  style={{fontSize: '11px'}}>{promptTool[prompt.tool] || prompt.tool || ''}</span>
                          </div>
                        </div>

                        {
                          !clickable && prompt.type === PromptType.Art && !!prompt.example?.length &&
                            <div style={{borderTop: '4px solid black', margin: '10px 0', padding: '5px 0'}}>
                                <div className="mt-2 mb-2"><b>Example Use:</b></div>
                                <Image.PreviewGroup
                                >
                                    <div className="flex justify-center items-center flex-wrap m-last-auto">
                                      {prompt.example.map((image) =>
                                        <Image onClick={preventClick}
                                               width={isMobile ? mobileImageWidth : imageWidth}
                                               style={{padding: 5}}
                                               height="auto"
                                               src={image}
                                        />)}
                                    </div>
                                </Image.PreviewGroup>
                            </div>
                        }

                      </div>
                    </div>
                  </div>

                </div>
              )}/>
    )
  );
};
