import CountUp from 'react-countup';
import {Topics} from './Topics';
import {Tags} from './Tags';
import {Category} from '../common/api';
import {TagItem} from './TagItem';
import {SearchBar} from './SearchBar';
import {defaultFilter} from '../common/utils/atoms';
import {Button, Empty, Spin} from 'antd';
import {CloseCircleFilled} from '@ant-design/icons';
import {Loader} from './Loader';
import React, {FC, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {PromptItem} from './PromptItem';
import {useUserBookmarksQuery} from '../common/hooks/useUser';
import {useCategoriesQuery} from '../common/hooks/useTags';
import {PromptType} from '../common/hooks/usePrompts';
import {Tool, ToolName} from '../common/enums/tool';


const PromptWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const PromptWrapperContainerInner = styled.div`
  max-width: 680px;
  margin: auto;
`;

const PromptWrapperContainer = styled.div`
  width: 100%;
  padding: 20px 20px 100px 20px;
  max-height: 100%;
  overflow-y: auto;
`;
const CategoriesWrapper = styled.div`
  max-width: 100%;
  display: block;
  overflow: hidden;
  border: 1px solid #e3e8f7;
  padding: 6px;
  border-radius: 12px;
  background-color: #ffffff;
`;
const PromptFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #f2f2f7;
  left: 0;
  width: 100%;
  gap: 20px;
  @media (max-width: 768px) {
    display: block;
    > div {
      justify-content: space-between;
    }

    > div:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;

const PromptCount = styled.div`
  font-weight: 500;
  padding-left: 140px;
  transition: 1.2s ease-in-out;
  @media (max-width: 768px) {
    padding-left: 100px;
  }
`;
const StickyContainer = styled.div`
  top: 60px;
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #f2f2f7;
  padding-top: 15px;
`;
const Fetching = styled.div`
  width: 100%;
  padding: 10px 20px;
  text-align: center;
`;

export const UnSaveButton = styled(Button)`
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0.04);

  &:hover {
    background-color: rgba(0, 0, 0, 0.09) !important;
    color: rgba(0, 0, 0, 0.7) !important;
    border-color: rgba(0, 0, 0, 0.09) !important;
  }
`;

const otherCategoryCode = 'other';
const tools = [
  {name: ToolName.Midjourney, id: Tool.Midjourney},
  {name: ToolName.StableDiffusion, id: Tool.StableDiffusion},
  {name: ToolName.DallE, id: Tool.DallE},
];

interface PromptPageProps {
  isMobile: boolean;
  promptType?: PromptType;
  promptsCount: number | null;
  setFilter: any;
  user: any;
  setQuery: any;
  isLoading: boolean;
  isFetchingNextPage: boolean;
  hasNextPage: boolean | undefined;
  fetchNextPage: any;
  filter: any;
  promptsData: any;
  query: any;
}

export const PromptPage: FC<PromptPageProps> = (
  {
    promptType,
    isMobile,
    promptsCount,
    setFilter,
    user,
    setQuery,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    filter,
    promptsData,
    query
  }
) => {
  const [page, setPage] = useState(0);

  const {data: userBookmarks} = useUserBookmarksQuery(!!user);
  const {data: categories} = useCategoriesQuery();

  const categoryData = useMemo(() => {
    if (!categories) return;
    return categories.items.filter((category) => category.code !== otherCategoryCode);
  }, [categories]);

  function onSelectCategory(currentCategory: Category): void {
    setQuery('');
    setFilter({
      ...filter,
      category: !filter?.category || filter?.category.id !== currentCategory?.id ? currentCategory : null,
      tag: null,
    });
  }

  function onSelectTool(currentTool: any): void {
    setQuery('');
    setFilter({
      tag: null,
      tool: !filter?.tool || filter?.tool.id !== currentTool?.id ? currentTool : null
    });
  }

  const renderPrompts = useMemo(() => {
    const isEmpty: boolean = !promptsData?.pages[0]?.total;
    return !isEmpty ? (
      promptsData?.pages.map((page: any) => (
        page?.items.map((prompt: any) => (
          <PromptItem
            authorized={!!user}
            bookmarked={userBookmarks?.find((p) => p.id === prompt.id)}
            key={prompt.id}
            prompt={prompt}
            onTagSelect={(tag) => {
              setQuery('');
              setFilter({...filter, tag, category: null});
            }
            }
          />
        ))
      ))
    ) : !(isLoading) &&
        <Empty className="fixed-center" description={`${query ? 'Nothing found!' : 'Prompts are empty!'}`}/>;
  }, [isLoading, promptsData, userBookmarks, user, query, setFilter, setQuery, filter]);


  useEffect(() => {
    async function fetchData(ev: Event) {
      ev.stopPropagation();
      ev.preventDefault();
      if (query) return;
      if (
        Math.ceil(window.innerHeight + window.scrollY) >= document.body.scrollHeight &&
        (hasNextPage == null || hasNextPage) &&
        !isFetchingNextPage
      ) {
        await fetchNextPage({pageParam: {page: page + 1}});
        setPage(page + 1);
      }
    }

    window.addEventListener('scroll', fetchData);
    return () => window.removeEventListener('scroll', fetchData)
  }, [page, hasNextPage, fetchNextPage, isFetchingNextPage, query]);

  const promptCountingText = useMemo(() => {
    switch (promptType) {
      case PromptType.Art:
        return ' Image Prompts';
      case PromptType.Text:
        return ' Text Prompts'
      default:
        return ' Prompts and Counting'
    }
  }, [promptType])

  return (
    <PromptWrapper>
      <StickyContainer>
        <PromptCount>
          <CountUp
            start={0}
            end={promptsCount ?? 0}
            duration={2.75}
            useEasing={true}
            separator=","
          />
          {promptCountingText}
        </PromptCount>

        <PromptFilterWrapper>
          {isMobile ? (
            <div className="mb-2">
              {promptType !== PromptType.Art ? (<Topics
                  fullWidth
                  selectedItems={filter.category ? [filter.category] : []}
                  onSelectItem={([c]) => onSelectCategory(c)}
                  canSelect={1}
                  data={categoryData ?? []}
                />) :
                (<Topics
                  fullWidth
                  selectedItems={filter.tool ? [filter.tool] : []}
                  onSelectItem={([c]) => onSelectTool(c)}
                  canSelect={1}
                  data={tools as any}
                />)}
            </div>
          ) : (
             <CategoriesWrapper>
              <Tags inline>
                {promptType !== PromptType.Art ? categoryData?.map((c) => (
                  <div key={c.id} style={{
                    display: 'flex',
                    opacity: (filter?.category as Category)?.id === c.id ? '0.5' : '1'
                  }}>
                    <TagItem key={c.id} onSelect={() => onSelectCategory(c)} tag={c.name}/>
                  </div>
                )) : (
                  tools.map((t) => (
                    <div key={t.id} style={{
                      display: 'flex',
                      opacity: (filter?.tool as Category)?.id === t.id ? '0.5' : '1'
                    }}>
                      <TagItem key={t.id} onSelect={() => onSelectTool(t)} tag={t.name}/>
                    </div>
                  ))
                )}
              </Tags>
            </CategoriesWrapper>)}

          <div className="ml-auto">
            <SearchBar
              query={query}
              setQuery={(value) => {
                setQuery(value);
                setFilter({...defaultFilter, tool: filter.tool});
              }}
            />
          </div>
        </PromptFilterWrapper>
      </StickyContainer>

      {(filter?.category || filter?.tag || filter?.tool) && (
        <div className="flex items-center w-full pt-2 pb-2 pl-8 pr-8 gap-2 overflowX-auto" style={{borderTop: '1px solid #e3e8f7'}}>
          <span className="no-wrap">Filtered by:</span> &nbsp;
          {!!filter?.category?.name && <TagItem tag={filter?.category?.name}/>}
          {!!filter?.tool?.name && <TagItem tag={filter?.tool?.name}/>}
          {!!filter?.tag as any && <TagItem tag={filter?.tag as any}/>}
          <Button type="text"
                  danger
                  shape="circle"
                  className="ml-2"
                  onClick={() => setFilter(defaultFilter)}
          >
            <CloseCircleFilled/>
          </Button>
        </div>
      )}

      <PromptWrapperContainer>
        <PromptWrapperContainerInner>
          {isLoading ? <Loader/> : renderPrompts}
        </PromptWrapperContainerInner>
        <Fetching>
          {isFetchingNextPage && <Spin tip="Fetching More..."/>}
        </Fetching>
      </PromptWrapperContainer>
    </PromptWrapper>
  );
};
