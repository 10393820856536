import React, {useEffect} from 'react';
import './App.scss';
import {useRoutes} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from 'react-query';
import {Toaster} from 'react-hot-toast';
import routes from './routes';
import {Layout, Space} from 'antd';
import {Header} from './components/Header';
import {useIsMobile} from './common/hooks/useIsMobile';
import {Footer} from './components/Footer';
import useScrollReset from './common/hooks/useScrollReset';
import {auth} from './firebase';
import {userAuthApi} from './common/api/user-api';
import {FirebaseUser} from './common/api';
import {useAtom} from 'jotai';
import {shouldReceiveNewsletters, userProfile} from './common/utils/atoms';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    }
  }
});

function App() {
  const content = useRoutes(routes);
  const isMobile = useIsMobile();
  const [newsletters] = useAtom(shouldReceiveNewsletters);
  const [user, setUser] = useAtom(userProfile);
  useScrollReset();

  useEffect(() => {
    async function fetchUser(user: any): Promise<void> {
      const token = await user?.getIdToken();
      if (token) {
        localStorage.setItem('token', token);
        await userAuthApi({newsletters});
      } else {
        localStorage.clear();
      }
      setUser(user?.providerData?.[0] as unknown as FirebaseUser);
    }

    async function reconnectUser(user: any): Promise<void> {
      const currentToken = localStorage.getItem('token');
      const token = await user?.getIdToken(true);
      if (currentToken && token && currentToken !== token) {
        localStorage.setItem('token', token);
        await userAuthApi({newsletters});
      }
    }

    const unsubscribeState = auth.onAuthStateChanged(u => fetchUser(u));
    const unsubscribeId = auth.onIdTokenChanged(u => reconnectUser(u));
    return () => {
      unsubscribeState();
      unsubscribeId();
    };
  }, [newsletters, setUser]);

  return (
    <QueryClientProvider client={queryClient}>
      <Space direction="vertical" style={{width: '100%', height: '100%'}}
             size={[0, 48]}>
        <Layout style={{backgroundColor: 'transparent'}}>
          <Header user={user} isMobile={isMobile}/>
          {content}
          <Footer/>
        </Layout>
      </Space>
      <Toaster position="bottom-left"/>
    </QueryClientProvider>
  );
}

export default App;
