import {useEffect} from 'react';
import {defaultFilter, promptFilter} from '../utils/atoms';
import {useAtom} from 'jotai';

export const useResetFilter = () => {
  const setFilter = useAtom(promptFilter)[1];
  useEffect(() => {
    return () => setFilter(defaultFilter);
  }, [setFilter]);
};
