import {useLayoutEffect} from 'react';

export function useOutsideAlerter(ref: any, handler: (ev: MouseEvent) => void) {
    useLayoutEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: MouseEvent) {
            if (
                (ref instanceof HTMLElement && !ref.contains(event.target as Node)) ||
                (ref?.current && !ref.current.contains(event.target))
            ) {
                handler(event);
            }
        }

        // Bind the event listener
        document.addEventListener('mouseup', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mouseup', handleClickOutside);
        };
    }, [ref, handler]);
}
