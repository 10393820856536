import styled from 'styled-components';
import {PromptType, usePromptByIdQuery} from '../common/hooks/usePrompts';
import React, {useState} from 'react';
import {Loader} from '../components/Loader';
import {PromptShare} from '../components/PromptShare';
import {useNavigate, useParams} from 'react-router-dom';
import {PromptItem} from '../components/PromptItem';
import {Button, Empty} from 'antd';
import {CompressOutlined, ExpandOutlined, LeftOutlined} from '@ant-design/icons';
import {useUser, useUserBookmarksQuery} from '../common/hooks/useUser';
import {ChatIcon} from '../components/ChatIcon';

const PromptDetailsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const PromptDetailsWrapperContainer = styled.div`
  width: 100%;
  padding: 40px 20px 100px 20px;
  max-height: 100%;
  overflow-y: auto;
`;

const PromptDetailsWrapperContainerInner = styled.div`
  max-width: 680px;
  margin: auto;
`;
const PromptContent = styled.div`
  background-color: white;
  position: relative;
`;
const ChatGPTAnswer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 8px;
  background-color: rgba(0, 0, 0, .8);
  color: white;
  padding: 22px;
  border-radius: 8px;
  line-height: 26px;
  font-weight: 500;
  transition: all .25s linear;

  .hidden {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const ChatIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  background-color: #3cc37d;
  border-radius: 50%;

  > svg {
    color: white;
  }
`;

export const PromptDetails = () => {
  const navigate = useNavigate();
  const {user} = useUser();
  const [expand, setExpand] = useState<boolean>(false);
  const {data: userBookmarks} = useUserBookmarksQuery(!!user);
  const {id: promptId} = useParams();
  const {data: promptDetails, isLoading} = usePromptByIdQuery(promptId);

  return (
    <PromptDetailsWrapper>
      <PromptDetailsWrapperContainer>
        <PromptDetailsWrapperContainerInner>
          {isLoading ? <Loader/> : promptDetails ? (
            (
              <PromptContent>
                <PromptItem
                  clickable={false}
                  authorized={!!user}
                  bookmarked={userBookmarks?.find((p) => p.id === promptDetails.id)}
                  prompt={promptDetails}
                  promptText
                >
                  <Button type="text" className="mr-4 p-1" onClick={() => navigate(-1)}>
                    <LeftOutlined/>
                  </Button>
                </PromptItem>
                {promptDetails.type === PromptType.Text &&
                    <div className="flex p-4 pt-1" style={{borderBottom: '1px solid #e3e8f7'}}>
                        <ChatIconWrapper className="mr-4">
                            <ChatIcon/>
                        </ChatIconWrapper>
                        <ChatGPTAnswer>
                            <div>
                              <pre className={`text-wrap${!expand ? ' hidden' : ''}`}>
                                  {promptDetails.example ?? ''}
                              </pre>
                            </div>
                            <Button style={{width: 'fit-content', marginLeft: 'auto', color: 'white'}}
                                    size="middle"
                                    shape="round"
                                    type="ghost"
                                    onClick={() => setExpand(!expand)}>
                              {expand ? <CompressOutlined/> : <ExpandOutlined/>}
                            </Button>
                        </ChatGPTAnswer>
                    </div>}
                <div className="flex items-center justify-center">
                  <PromptShare
                    promptContent={promptDetails}
                  />
                </div>
              </PromptContent>
            )
          ) : (
            <Empty className="fixed-center"
                   description="Somtiing when wrong"/>
          )}
        </PromptDetailsWrapperContainerInner>
      </PromptDetailsWrapperContainer>
    </PromptDetailsWrapper>
  );
};
