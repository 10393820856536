import {useInfiniteQuery, useMutation, useQuery} from 'react-query';
import {createPromptsApi, getPromptByIdApi, getPromptsApi} from '../api/client.api';
import {Order} from '../enums/order';

export enum PromptType {
  Text = 'text',
  Art = 'art'
}

export const usePromptsQuery = (
  search: string | undefined = undefined,
  size: number = 100,
  tag: string | undefined = undefined,
  category: string | undefined = undefined,
  type: PromptType | undefined = undefined,
  tool: string | undefined = undefined,
  page: number = 0,
  order: Order = Order.Desc,
  sort: 'createdAt!!date' | 'createdAt!!date-active' | 'usedCount' = 'usedCount',
) =>
  useInfiniteQuery({
    queryKey: ['prompts', page, tag, category, type, tool],
    queryFn: ({pageParam}) => {
      if (page === 0 || pageParam?.hasNextPage) {
        return getPromptsApi({page: pageParam?.page || page, size, search, order, sort, tag, category, type, tool});
      }
    },
    getNextPageParam: (lastPage) => ({
      hasNextPage: Math.ceil(page * (lastPage as any)?.items?.length) < (lastPage as any)?.total
    }),
    queryHash: search,
    keepPreviousData: true,
  });

export const usePromptsSearchQuery = (search: string | undefined) =>
  useQuery({
    queryKey: ['prompts-query', search],
    queryFn: () => getPromptsApi({search}),
    enabled: !!search
  });

export const usePromptByIdQuery = (id: string | undefined) =>
  useQuery({
    queryKey: ['prompt-details', id],
    queryFn: () => getPromptByIdApi(id as string),
    enabled: id != null
  });

export const usePromptsMutation = () => {
  const {mutate: createPrompt, isLoading} = useMutation(createPromptsApi);
  return {createPrompt, isLoading};
};
