import React, {useMemo} from 'react';
import {BookmarkItem} from '../components/BookmarkItem';
import {Empty} from 'antd';
import {Loader} from '../components/Loader';
import {useUser, useUserBookmarksMutation, useUserBookmarksQuery} from '../common/hooks/useUser';
import styled from 'styled-components';
import {useQueryClient} from 'react-query';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const WrapperContainer = styled.div`
  max-width: 680px;
  width: 100%;
  margin: auto;
  padding: 20px 20px 150px 20px;
`;
export const UserBookmarks = () => {
  const queryClient = useQueryClient();
  const {user} = useUser();

  const {data: userBookmarks, isLoading} = useUserBookmarksQuery(!!user);
  const {removeBookmark} = useUserBookmarksMutation();


  const renderBookmarks = useMemo(() => {
    return userBookmarks?.length ? (userBookmarks?.map((bookmark) => (
      <BookmarkItem
        key={bookmark.id}
        bookmark={bookmark}
        onRemove={(id) => {
          removeBookmark(id, {
            onSuccess: () => {
              queryClient.invalidateQueries('user-bookmarks');
            }
          });
        }
        }
      />
    ))) : (<Empty className="fixed-center"
                  description="Bookmarks are empty"/>);
  }, [userBookmarks, removeBookmark, queryClient]);


  return (
    <Wrapper>
      <WrapperContainer>
        {isLoading ? (<Loader/>) : (renderBookmarks)}
      </WrapperContainer>``
    </Wrapper>

  );
};
