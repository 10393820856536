import React from 'react';
import styled from 'styled-components';

const PrivacyWrapper = styled.footer`
  padding: 40px 40px 100px 40px;
  @media (max-width: 768px) {
    padding: 20px 20px 100px 20px;
  }
`;

export const Privacy = () => (
  <PrivacyWrapper>
    <p>
      Last updated November 09, 2022 <br/>
      This privacy notice for Prompt Pup ("we," "us," or "our") describes how and why we might collect, store, use,
      and/or share ("process") your information when you use our services ("Services"), such as when you:
      <li>Visit our website at PromptPup.net, or any website of ours that links to this privacy notice</li>
      <li>
        Engage with us in other related ways, including any sales, marketing, or events.
        Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices.
        If you do not agree with our policies and practices, please do not use our services. If you still have any
        questions or concerns, please contact us via our support page.
      </li>
    </p>

    <h2>Personal Data We Collect</h2>

    <h3>Personal information you disclose to us</h3>
    <p>
      We collect information that you voluntarily provide to us when you register on the Services, express an interest
      in obtaining information about us or our products and Services, when you participate in activities on the
      Services, or otherwise when you contact us.
      The personal information we collect may include the following:
      <li>email addresses</li>
      <li>usernames</li>
      <li>passwords</li>
      We do not process sensitive information.
    </p>

    <h3>Personal Data We Obtain From Third Parties</h3>
    <p>
      Google – You can sign up and log in to Prompt Pup using your Google account. When you sign up using Google,
      Google will ask your permission to share certain information from your Google account with PromptPup.net. This
      includes your Google email address and avatar (optional). This information is collected by Google and is
      provided to us under the terms of Google’s privacy policy. You can regulate the information that we receive from
      Google using your Google activity controls. If you signed up using Google by mistake, you can delete your
      account from the Account page within your Settings at any time, or you can contact us by the support link on our
      site.
    </p>
    <p>
      Facebook – You can sign up and log in to Prompt Pup using your Facebook account. When you sign up using
      Facebook, Facebook will ask your permission to share certain information from your Facebook account with
      PromptPup.net. This includes your Facebook email address and avatar (optional). This information is collected by
      Facebook and is provided to us under the terms of Facebook's privacy policy. You can regulate the information
      that we receive from Facebook using your Facebook activity controls. If you signed up using Facebook by mistake,
      you can delete your account from the Account page within your Settings at any time, or you can contact us by the
      support link on our site.
    </p>

    <h2>How We Use Your Personal Data</h2>
    <p>We use your personal data to:</p>
    <li>
      Provide, Improve, and Develop the Platform - We process your personal data primarily for the adequate
      performance of our website. We may also process your personal data in light of our legitimate interest in
      improving the Website and giving our users the best experience. We do this to: a) Operate and maintain your
      Prompt Pup account, and to provide you with access to and use of the Platform. Your email address and password
      are used to identify you when you log into the Platform. ** Identify you as the creator of the prompts that you
      upload, the comments that you post and/or the other contributions that you make to the Website. b) Respond to
      you about any comment or enquiry you have submitted.
    </li>
    <li>Maintain a Safe and Secure Environment - We also process your personal data in light of our legitimate
      interest in improving the security of the Platform (including the security of your personal data) in order to
      give our users the best experience.
    </li>

    <h3>How We Share Your Personal Data</h3>
    <li>Through Your Profile – Any personal data that you choose to disclose in your public profile on FPrompt Pup
      will be accessible by other users of the website, who may view your profile information, view or and comment on
      any of your public prompts.
    </li>
    <li>Third Party Applications – If you choose to connect your Prompt Pup account to other apps or social networks
      such as Facebook and Twitter, you may be sharing certain personal data with these apps or networks. Materials
      that you share with social media networks will appear on your timeline (or similar functions) as well as in
      feeds that may be viewed by the public or by certain followers, subject to the terms and conditions of such
      social media networks.
    </li>
    <li>
      Compliance with Laws – We may disclose your personal data to courts, law enforcement agencies, and governmental
      authorities (i) to comply with our legal obligations, (ii) to comply with legal process and to respond to claims
      asserted against Prompt Pup, (iii) to respond to verified requests relating to a criminal investigation or
      alleged or suspected illegal activity or any other activity that may expose us, you, or any other of our users
      to legal liability, (iv) to enforce and administer our Terms of Use and other agreements with users, or (v) to
      protect the rights, property or personal safety of Prompt Pup, its employees, and members of the public.
    </li>

    <h2>Information Security</h2>
    <p>Prompt Pup maintains appropriate technical, administrative, and physical safeguards that are designed to
      prevent unauthorized access, use or disclosure of personal data we collect or store and to ensure a level of
      security appropriate to the risk of varying likelihood and severity for the rights and freedoms of natural
      persons that may be involved with our processing operations. We regularly monitor our systems for possible
      vulnerabilities and attacks. However, it is not possible to guarantee the security of personal data transmitted
      over the Internet. You use the website and provide us with personal data at your own discretion and risk.</p>

  </PrivacyWrapper>
);
