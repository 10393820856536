import {usePromptsQuery} from '../common/hooks/usePrompts';
import React, {useEffect, useState} from 'react';
import {useUser} from '../common/hooks/useUser';
import {useAtom} from 'jotai';
import {promptCount, promptFilter} from '../common/utils/atoms';
import {useIsMobile} from '../common/hooks/useIsMobile';
import {useDebounce} from '../common/hooks/useDebounce';
import {PromptPage} from '../components/PromptPage';
import {useResetFilter} from '../common/hooks/useResetFilter';

const ITEMS_COUNT = 100;


export const HomePage = () => {
  const isMobile = useIsMobile();
  const [promptsCount, setPromptsCount] = useAtom(promptCount);
  const [filter, setFilter] = useAtom(promptFilter);
  const {user} = useUser();
  const [query, setQuery] = useState<string>('');
  const debouncedQuery = useDebounce(query);
  useResetFilter();

  const {
    data: promptsData,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = usePromptsQuery(debouncedQuery, ITEMS_COUNT, filter?.tag?.toLowerCase(), filter?.category?.id);

  useEffect(() => {
    setPromptsCount(promptsData?.pages?.[0]?.total ?? 0);
  }, [promptsData, promptsCount, setPromptsCount, setFilter]);

  return (
    <PromptPage isMobile={isMobile}
                query={query}
                promptsCount={promptsCount}
                promptsData={promptsData}
                filter={filter}
                setFilter={setFilter}
                user={user}
                setQuery={setQuery}
                isLoading={isLoading}
                isFetchingNextPage={isFetchingNextPage}
                hasNextPage={hasNextPage}
                fetchNextPage={fetchNextPage}
    />
  );
};
