import {Bookmark} from './index';
import {request} from './request';

export const userAuthApi = async (newsletters = {}) => {
  return await request(`client/auth`, {
    body: JSON.stringify(newsletters),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'post',
  });
};

export const createUserBookmarksApi = async (promptId: string) => {
  return await request(`client/my-prompt-bookmarks`, {
    body: JSON.stringify({prompt: promptId}),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'post',
  });
};

export const getUserBookmarksApi = async (): Promise<Bookmark[]> => {
  return await request(`client/my-prompt-bookmarks`);
};

export const removeUserBookmarkApi = async (id: string): Promise<Bookmark[]> => {
  return await request(`client/my-prompt-bookmarks/${id}`, {
    method: 'delete',
  });
};

export const promptUsagesByIdApi = async (promptId: string) => {
  return await request(`client/my-prompt-usages`, {
    body: JSON.stringify({prompt: promptId}),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'post',
  });
};
