import styled from 'styled-components';
import React, {FC} from 'react';
import {Bookmark} from '../common/api';
import {BookOutlined, DeleteOutlined} from '@ant-design/icons';
import {UseItButton} from './UseItButton';
import {calculateDaysAgo} from '../common/utils/helpers';
import {UnSaveButton} from './PromptPage';
import {useNavigate} from 'react-router-dom';
import {PromptType} from '../common/hooks/usePrompts';
import {Image} from 'antd';
import {useIsMobile} from '../common/hooks/useIsMobile';


const PromptWrapper = styled.div`
  background-color: white;
  margin: 16px 0;
  padding: 10px 20px;
`;

interface BookmarkItemProps {
  bookmark: Bookmark;
  onRemove: (id: string) => void;
}

const mobileImageWidth = 130;
const imageWidth = 200;

export const BookmarkItem: FC<BookmarkItemProps> = ({bookmark, onRemove}) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  function preventClick(ev: any): void {
    ev.stopPropagation();
    ev.preventDefault();
  }

  return (
    !!bookmark && (
      <PromptWrapper
        onClick={(ev) => {
          preventClick(ev);
          navigate(`/prompt-details/${bookmark.id}`);
        }}
      >
        <h3 className="mb-4">{bookmark.title}</h3>
        <div className="flex">
          {bookmark.type === PromptType.Art && !!bookmark.preview && (
            <div className="p-1 pl-0">
              <Image preview={false}
                     width={isMobile ? mobileImageWidth : imageWidth}
                     src={bookmark.preview}
                     style={{padding: 5}}
              />
            </div>
          )}
          <p className="text-black">{bookmark.description}</p>
        </div>

        <section className="flex items-center flex-wrap justify-between pt-2 pb-2 mb-4">

          <UnSaveButton
            icon={<DeleteOutlined/>}
            size="large"
            onClick={(ev) => {
              preventClick(ev);
              onRemove(bookmark.id);
            }}
          >Remove</UnSaveButton>

          <UseItButton promptText={bookmark.prompt}
                       promptId={bookmark.id}
                       promptType={bookmark.tool}
                       authorized/>

        </section>

        <div className="flex items-center justify-between gap-2 pb-2">
          <div className=""
               style={{fontWeight: 500}}
          >
            &nbsp; <BookOutlined/>&nbsp; {bookmark.bookmarkedCount} Saved
          </div>

          <div className="no-wrap mr-2 fw-m" style={{fontSize: '11px'}}>&nbsp;{calculateDaysAgo(bookmark.createdAt)}</div>
        </div>

      </PromptWrapper>
    )
  );
};
