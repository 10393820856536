import {usePromptsSearchQuery} from '../common/hooks/usePrompts';
import React, {FC, useRef, useState} from 'react';
import styled from 'styled-components';
import Draggable from 'react-draggable';
import {Empty, Spin} from 'antd';
import {useOutsideAlerter} from '../common/hooks/useOutsideClick';
import {SearchBar} from './SearchBar';
import {useNavigate} from 'react-router-dom';
import {Prompt} from '../common/api';
import {useDebounce} from '../common/hooks/useDebounce';

const SpotlightSearchWrapper = styled.div`
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 999;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  width: 90%;
  max-width: 600px;
  min-width: 250px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #e3e8f7;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
`;

const SpotlightSearchDropdown = styled.div`
  width: 100%;
  height: 100%;
  max-height: 400px;
  overflow: hidden;
  overflow-y: auto;
  min-height: 70px;
  @media (max-width: 768px) {
    max-height: 325px;
  }
`;

const SearchContainer = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SpotlightItem = styled.div`
  padding: 20px;
  border-top: 1px solid #e3e8f7;
  border-bottom: 1px solid #e3e8f7;
  cursor: pointer;

  > div {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  > div:first-child {
    margin-bottom: 5px;
  }

  &:hover {
    background-color: #f4f5f8;

    > div {
      font-weight: 500;
    }
  }
`;
export const SpotlightSearch: FC<{ onClose?: () => void }> = ({onClose}) => {
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const [query, setQuery] = useState<string>('');
  const debouncedQuery = useDebounce(query);
  const {data, isLoading} = usePromptsSearchQuery(debouncedQuery);
  useOutsideAlerter(containerRef, () => onClose?.());

  function onItemSelect(item: Prompt): void {
    navigate(`/prompt-details/${item.id}`);
    onClose?.();
  }

  return (
    <SpotlightSearchWrapper>
      <Draggable position={{x: document.body.clientWidth / 2, y: document.body.clientHeight / 4}}
                 positionOffset={{x: '-50%', y: '0%'}}
                 defaultClassName="dragable-container">
        <ContentContainer ref={containerRef}>
          <SearchContainer>
            <SearchBar
              autoFocus
              query={query}
              placeholder="Spotlight Search..."
              setQuery={setQuery}
            />
          </SearchContainer>

          <SpotlightSearchDropdown>
            {isLoading ? (
              <div className="flex items-center justify-center p-4">
                <Spin tip="loading" className="m-auto" size="large"/>
              </div>
            ) : (
              !!query && !data?.items?.length ? <Empty className="p-2" description="Nothing Found"/> : (
                data?.items.map((item) => (
                  <SpotlightItem key={item.id}
                                 onTouchEnd={() => onItemSelect(item)}
                                 onMouseDown={() => onItemSelect(item)}
                  >
                    <div>{item.title}</div>
                    <div>{item.description}</div>
                  </SpotlightItem>
                ))
              )
            )}
          </SpotlightSearchDropdown>

        </ContentContainer>
      </Draggable>
    </SpotlightSearchWrapper>
  );

};
