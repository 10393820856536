export const request = async (input: string, obj = {}) => {
    const token = localStorage.getItem('token');
    return fetch(`${process.env.REACT_APP_API_URL}/${input}`, {
        ...obj,
        headers: {
            ...((obj as any).headers ?? {}),
            ...(token ? {Authorization: `Bearer ${token}`} : {}),
        },
    })
        .then(async (response) => {
            if (!response.ok) {
                const res = await response.json();
                throw Error(res.message || 'Something went wrong.');
            }
            return response.json();
        })
        .catch((error) => {
            throw Error(error);
        });
};
