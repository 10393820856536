import {Navigate, RouteObject} from 'react-router-dom';
import React from 'react';
import {TextPromptsPage} from './pages/TextPromptsPage';
import {PromptForm} from './pages/PromptForm';
import {UserBookmarks} from './pages/UserBookmarks';
import AuthGuard from './components/AuthGuard';
import {Articles} from './pages/Articles';
import {Privacy} from './pages/Privacy';
import {Terms} from './pages/Terms';
import {PromptDetails} from './pages/PromptDetails';
import {ImagePromptsPage} from './pages/ImagePromptsPage';
import {HomePage} from './pages/HomePage';

const routes: RouteObject[] = [
    {
      path: '/',
      element: <HomePage/>,
    },
    {
      path: '/text-prompts',
      element: <TextPromptsPage/>,
    },
    {
      path: '/image-prompts',
      element: <ImagePromptsPage/>,
    },
    {
      path: 'prompt-details/:id',
      element: <PromptDetails/>,
    },
    {
      path: '/create',
      element: <PromptForm/>,
    },
    {
      path: '/privacy',
      element: <Privacy/>,
    },
    {
      path: '/terms',
      element: <Terms/>,
    },
    {
      path: '/articles',
      element: <Articles/>,
    },
    {
      path: '/',
      element: <AuthGuard/>,
      children: [
        {
          path: '/bookmarks',
          element: <UserBookmarks/>,
        }
      ]
    },
    {
      path: '/*',
      element: <Navigate to="/"/>
    },
  ]
;

export default routes;
