import styled from 'styled-components';
import {Formik} from 'formik';
import {Button, Input, Popover, Select} from 'antd';
import {QuestionCircleOutlined, SendOutlined} from '@ant-design/icons';
import React, {useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import {usePromptsMutation} from '../common/hooks/usePrompts';
import {useCategoriesQuery, useTagsQuery} from '../common/hooks/useTags';
import {useUser} from '../common/hooks/useUser';
import * as Yup from 'yup';
import {useQueryClient} from 'react-query';
import toast from 'react-hot-toast';
import {FirebaseModal} from '../components/FirebaseModal';
import {TagItem} from '../components/TagItem';

const promptDefaultValues: PromptFormData = {
  title: '',
  description: '',
  prompt: ''
};

interface PromptFormData {
  title: string;
  description: string;
  prompt: string;
}

const PromptFormWrapper = styled.div`
  padding: 30px 20px 100px 20px;
  margin: 0 auto;
  max-height: 100%;
  overflow-y: auto;
  @media (max-width: 768px) {
    padding-top: 10px;
  }
`;

const InfoText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgb(33, 36, 44);
  margin-bottom: 20px;
  margin-top: 20px;
`;
const TooltipInfo = styled.div`
  max-width: 300px;
`;
const TagCategoryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 8px;
`;

const TitleCategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: block;
  }
`;

const validationSchema = Yup.object({
  title: Yup.string().required(),
  description: Yup.string().required(),
  prompt: Yup.string().required(),
});

export const PromptForm = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {user} = useUser();
  const [page, setPage] = useState(0);
  const [category, setCategory] = useState<string>();
  const [query, setQuery] = useState<string>('');
  const [tags, setTags] = useState<string[]>([]);
  const [openFirebaseModal, setOpenFirebaseModal] = useState<boolean>(false);
  const {data: categoriesData} = useCategoriesQuery();
  const {data: tagsData, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage} = useTagsQuery(query);
  const {createPrompt} = usePromptsMutation();

  const options = useMemo(() => {
    if (!categoriesData) return;
    return categoriesData.items.map((category) => ({label: category.name, value: category.id}));
  }, [categoriesData]);

  const tagsOptions = useMemo(() => {
    if (!tagsData?.pages?.length) return;
    return tagsData.pages.flatMap(
      (page) => page?.items?.flatMap((item) => ({label: item.name, value: item.id})));
  }, [tagsData]);

  function onSave(form: PromptFormData): void {
    if (!category || tags?.length > 5) return;
    if (!user) {
      setOpenFirebaseModal(true);
    } else {
      createPrompt({
        prompt: form.prompt,
        title: form.title,
        description: form.description,
        category: category,
        tags: tags ?? []
      }, {
        onSuccess: () => {
          toast.success('Success');
          queryClient.invalidateQueries();
          navigate('/');
        },
        onError: () => {
          toast.error('Something went wrong');
        }
      });
    }
  }

  const onScroll = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (!event.target) return;
    const {scrollTop, offsetHeight, scrollHeight} = event.target;
    if (
      scrollTop + offsetHeight === scrollHeight &&
      (hasNextPage == null || hasNextPage) &&
      !isFetchingNextPage && !isLoading
    ) {
      await fetchNextPage({pageParam: {page: page + 1}});
      setPage(page + 1);
    }
  };

  return (
    <PromptFormWrapper>

      <FirebaseModal open={openFirebaseModal}
                     setOpen={(open) => setOpenFirebaseModal(open)}
      />

      <h1>New Prompt</h1>

      <Formik initialValues={promptDefaultValues} validationSchema={validationSchema}
              onSubmit={onSave}>
        {({values, errors, submitCount, handleChange, handleSubmit, isValid, dirty, touched}) => (
          <form onSubmit={handleSubmit}>

            <TitleCategoryWrapper className="flex items-center justify-between gap-4">
              <div className="flex-2">
                <label className="line-height-m mb-1">Title</label>
                <Input status={`${errors.title ? 'error' : ''}`} style={{height: 40}} value={values.title}
                       placeholder="Title" size="middle"
                       className="mb-4" name="title"
                       onChange={handleChange}/>
              </div>

              <div className="flex-1">
                <label style={{color: submitCount && !category ? 'red' : 'inherit'}}
                       className="line-height-m mb-1">
                  Select Category
                </label>
                <Select
                  size="large"
                  style={{width: '100%', minWidth: 250}}
                  className="mb-4"
                  placeholder="Select Category"
                  bordered
                  popupClassName="popupClassName"
                  onChange={(categoryId) => setCategory(categoryId)}
                  options={options}
                />
              </div>
            </TitleCategoryWrapper>

            <TagCategoryWrapper>

              <div style={{width: '100%'}}>
                <div className="line-height-m mb-1"
                     style={{color: submitCount && (tags?.length > 5) ? 'red' : 'inherit'}}>
                  <label>Select Tags (Up to 5) </label>
                  <Popover content={
                    <TooltipInfo>
                      <div>
                        You can add #TAGS that will turn into fillable text fields in the template. Try
                        typing # on your
                        prompt and start creating tags for your template. You can use #TAGS more than once
                        in your prompt. All
                        created tags will be listed here.
                      </div>
                    </TooltipInfo>
                  } title="Tags">
                    <QuestionCircleOutlined className="pointer"/>
                  </Popover>
                </div>

                <div className="flex justify-between">

                  <Select
                    style={{width: '100%'}}
                    placeholder="+ Add"
                    onPopupScroll={onScroll}
                    size="large"
                    onChange={(tags) => {
                      setTags(tags);
                      setQuery('');
                    }}
                    value={tags}
                    tagRender={(item) => (
                      <div style={{margin: '2px 5px'}}>
                        <TagItem onClose={item.onClose} tag={item.value}/>
                      </div>
                    )}
                    autoClearSearchValue
                    maxTagCount="responsive"
                    showSearch={false}
                    mode="tags"
                    options={tagsOptions as any ?? []}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    searchValue={query}
                    onSearch={(value) => setQuery(value)}
                  />

                </div>
              </div>
            </TagCategoryWrapper>

            <label className="line-height-m">Prompt</label>
            <TextArea status={`${errors.prompt ? 'error' : ''}`} value={values.prompt} className="mb-2"
                      name="prompt" rows={6} onChange={handleChange}/>

            <label className="line-height-m">Description</label>
            <TextArea status={`${errors.description ? 'error' : ''}`} value={values.description} className="mb-2"
                      name="description" rows={3} onChange={handleChange}/>

            <InfoText>
              Be mindful we don’t take responsibility for any actions taken by third parties based on the
              information you decide to disclose. Avoid sharing any sensitive information.
            </InfoText>

            <div className="flex items-center flex-end">

              <Button className="color-black" type="link" size="middle" onClick={() => navigate('/')}>
                Cancel
              </Button>
              <Button className="bg-black ml-2"
                      size="middle"
                      shape="round"
                      htmlType="submit"
                      type="primary"
                      disabled={!isValid}
                      icon={<SendOutlined/>}
              >
                Submit Prompt
              </Button>
            </div>

          </form>
        )}
      </Formik>

    </PromptFormWrapper>
  );
};
