import {getArticleById, getArticles} from '../api/client.api';
import {Order} from '../enums/order';
import {useQuery} from 'react-query';

export const useArticlesQuery = (
  search: string | undefined = undefined,
  size: number = 100,
  page: number = 0,
  order: Order = Order.Asc,
  sort: 'createdAt!!date' | 'createdAt!!date-active' | 'index' = 'index'
) =>
  useQuery({
    queryKey: ['articles', page],
    queryFn: () => getArticles({page, size, search, order, sort}),
    queryHash: search,
    keepPreviousData: true,
  });

export const useArticleByIdQuery = (
  id: string
) => useQuery(
  ['articles', id],
  () => getArticleById(id),
  {enabled: id != null, keepPreviousData: true}
);
