import React from 'react';
import styled from 'styled-components';

const TermsWrapper = styled.footer`
  padding: 40px 40px 100px 40px;
  @media (max-width: 768px) {
    padding: 20px 20px 100px 20px;
  }
`;
export const Terms = () => (
  <TermsWrapper>
    <h2>Terms of Service</h2>

    <h3>1. Introduction</h3>
    <p>These Terms of Service ("Terms") govern your access to and use of the website, products, and services
      (collectively, "Services") provided by Prompt Pup ("we", "us", or "our"). By accessing or using our Services, you
      agree to be bound by these Terms and our Privacy Policy.</p>

    <h3>2. Account Registration</h3>
    <p>You may need to create an account to access certain features of our Services. If you create an account, you must
      provide accurate and complete information and keep the account information up-to-date. You are responsible for
      maintaining the confidentiality of your account and password and for any activities that occur under your account.
      Prompt Pup reserves the right to refuse service, terminate accounts, or remove or edit content, in its sole
      discretion.</p>

    <h3>3. User Content and Conduct</h3>

    <h4>3.1 User Content</h4>
    <p>By posting, uploading, or otherwise submitting any content, including without limitation, text, images, videos,
      and links, to Prompt Pup (collectively, "User Content"), you grant us a non-exclusive, royalty-free, worldwide,
      perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create
      derivative works from, distribute, and display such content throughout the world in any media.</p>

    <h4>3.2 User Conduct</h4>
    <p>You agree not to use our Services to: <br/>
      Post, upload, or otherwise submit any User Content that:</p>
    <li>a. is defamatory, obscene, pornographic, vulgar, or offensive;</li>
    <li>b. infringes on the intellectual property, trade secret, privacy, or other proprietary rights of any third
      party;
    </li>
    <li>c. contains software viruses or any other computer code, files or programs designed to interrupt, destroy or
      limit the functionality of any computer software or hardware or telecommunications equipment;
    </li>
    <li>d. constitutes unauthorized or unsolicited advertising, junk or bulk email (also known as "spamming"), chain
      letters, any other form of unauthorized solicitation, or any form of lottery or gambling;
    </li>
    <li>e. contains false or misleading information or impersonates any person or entity, including without limitation,
      an official or representative of Prompt Pup;
    </li>
    <li>f. violates any applicable laws or regulations; or</li>
    <li>g. promotes illegal or harmful activities or substances;</li>
    <li>Harass, threaten, or defraud other users or members of Prompt Pup;</li>
    <li>Violate any procedures, policies, or regulations of networks connected to our Services;</li>
    <li>Interfere with, disrupt, or create an undue burden on the Services or the networks or services connected to the
      Services;
    </li>
    <li>Attempt to gain unauthorized access to the Services, other accounts, or computer systems or networks connected
      to the Services;
    </li>
    <li>Use any robot, spider, scraper, or other automated means to access the Services for any purpose;</li>
    <li>Reverse engineer, decompile, disassemble, or otherwise attempt to discover the source code of the Services;</li>
    <li>Modify, adapt, or hack the Services, or falsely imply that some other site is associated with the Services or
      Prompt Pup;
    </li>
    <li>Reproduce, duplicate, copy, sell, resell, or exploit any portion of the Services without the express written
      permission of Prompt Pup.
    </li>

    <h3>4. Termination</h3>
    <p>Prompt Pup may, in its sole discretion, terminate or suspend your access to the Services, without notice, for any
      reason, including but not limited to, breach of these Terms. Upon termination, all rights granted to you under
      these Terms will immediately cease. Prompt Pup will not be liable to you or any third party for termination of
      your access to the Services.</p>

    <h3>5. Disclaimer of Warranties and Limitation of Liability</h3>
    <p>The Services are provided on an "AS IS" and "AS AVAILABLE" basis. To the fullest extent permitted by applicable
      law, Prompt Pup disclaims all warranties, express or implied, including but not limited to, implied warranties of
      merchantability, fitness for a particular purpose, and non-infringement. Prompt Pup does not warrant that the
      Services will meet your requirements or that the Services will be uninterrupted, timely, secure, or error-free.
      In no event shall Prompt Pup, its affiliates, or their respective officers, directors, employees, or agents be
      liable for any direct, indirect, incidental, special, consequential, or punitive damages, including but not
      limited to, loss of profits, goodwill, use, data, or other intangible losses, resulting from your access to or use
      of, or your inability to access or use, the Services, whether based on warranty, contract, tort (including
      negligence), or any other legal theory.</p>

    <h3>6. Changes to Terms</h3>
    <p>Prompt Pup may modify these Terms at any time and in its sole discretion. If we make changes to these Terms, we
      will update the "Last Updated" date at the top of this page. By continuing to access or use our Services after any
      revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you
      must stop using the Services.</p>

    <h3>7. Governing Law and Dispute Resolution</h3>
    <p>These Terms will be governed by and construed in accordance with the laws of the jurisdiction where Prompt Pup is
      located, without regard to its conflict of law provisions. Any disputes arising from or relating to these Terms or
      the Services shall be resolved through good faith negotiations between the parties. If such negotiations fail to
      resolve the dispute, the parties agree to submit the dispute to binding arbitration under the rules of the
      applicable arbitration organization, with the prevailing party entitled to recover its reasonable attorneys' fees
      and costs.</p>

    <h3>8. Miscellaneous</h3>
    <p>If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or
      eliminated to the minimum extent necessary so that these Terms will otherwise remain in full force and effect and
      enforceable. The failure of Prompt Pup to exercise or enforce any right or provision of these Terms does not
      constitute a waiver of such right or provision. These Terms, and any rights and licenses granted hereunder, may
      not be transferred or assigned by you, but may be assigned by Prompt Pup without restriction. These Terms
      constitute the entire agreement between you and Prompt Pup concerning the Services and supersede and replace any
      prior agreements between you and Prompt Pup with respect to the Services.</p>
  </TermsWrapper>
);
