import {Button, Tooltip} from 'antd';
import {CopyOutlined} from '@ant-design/icons';
import React, {FC} from 'react';
import {useUserBookmarksMutation} from '../common/hooks/useUser';

interface UseItButtonProps {
  promptId: string;
  promptText: string;
  authorized: boolean;
  handleSignIn?: () => void;
  promptType: string;
}

const chatGptUrl = 'https://chat.openai.com/';
const midjourneyUrl = 'https://www.midjourney.com/app/';
const stableDiffusionUrl = 'https://stablediffusionweb.com/';

export const UseItButton: FC<UseItButtonProps> = ({promptId, promptText, authorized, promptType}) => {
  const {promptUsages} = useUserBookmarksMutation();

  const fetchNavigateTo = (): string => {
    switch (promptType) {
      case 'chatgpt-3.5':
        return chatGptUrl;
      case 'midjourney':
        return midjourneyUrl;
      case 'sdxl1':
        return stableDiffusionUrl;
      default:
        return '';
    }
  };

  const handleUseItClick = async (ev: any) => {
    ev.stopPropagation();
    ev.preventDefault();
    if (authorized) {
      promptUsages(promptId);
    }
    await navigator.clipboard.writeText(promptText ?? '');
    const url = fetchNavigateTo();
    if (!url) return;
    setTimeout(() => window.open(url, '_blank'), 700);
  };

  return (
    <Tooltip title="Copied to clipboard." trigger="click">
      <Button
        className="ml-auto"
        icon={<CopyOutlined/>}
        size="large"
        onClick={handleUseItClick}
      ><b> Use It!</b></Button>
    </Tooltip>
  );
};
