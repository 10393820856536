export enum Tool {
  Chatgpt3 = 'chatgpt-3.5',
  DallE = 'dall-e',
  Midjourney = 'midjourney',
  StableDiffusion = 'sdxl1'
}

export enum ToolName {
  Chatgpt3 = 'ChatGPT 3.5',
  DallE = 'Dall-E',
  Midjourney = 'Midjourney',
  StableDiffusion = 'Stable Diffusion',
}
