import React, {FC} from 'react';
import styled from 'styled-components';
import {NavLink} from 'react-router-dom';

const FooterWrapper = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  border-top: 1px solid #e3e8f7;
  z-index: 1;

  > div:first-child {
    font-size: 12px;
  }

  > div:last-child {
    font-size: 14px;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    display: block;
    > div:first-child {
      font-size: 10px;
    }

    > div:last-child {
      width: 100%;
      font-size: 12px;
      display: flex;
      align-items: center;
      margin-top: 10px;
    }
  }
`;

const linkStyles = {color: 'black', fontWeight: 500, marginLeft: '20px'};

export const aboutArticleId = '965227f5-8e9d-45f7-ae4e-07bce62def12';

export const Footer: FC = () => {

  return (
    <FooterWrapper>
      <div>© Copyright 2023 PromptPup - The doggone best place to find Al prompts for
        ChatGPT, Bard, Bing, Midjourney, and more.
      </div>
      <div className="flex items-center flex-end">
        <NavLink style={linkStyles} to="/privacy">Privacy</NavLink>
        <NavLink style={linkStyles} to="/terms">Terms</NavLink>
        <NavLink style={linkStyles} to="/articles" state={aboutArticleId}>About</NavLink>
        <a style={linkStyles} href="https://promptpup.freshdesk.com/"
           rel="noreferrer"
           target="_blank">Support</a>
      </div>
    </FooterWrapper>
  );
};
