import React, {FC} from 'react';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton
} from 'react-share';
import styled from 'styled-components';
import {Button, Input, Tooltip} from 'antd';
import {CopyOutlined} from '@ant-design/icons';
import {useIsMobile} from '../common/hooks/useIsMobile';
import {Prompt} from '../common/api';


const PromptShareWrapper = styled.div`
  padding: 0 20px 24px 20px;
`;

interface PromptShareProps {
  promptContent: Prompt;
}
const getFacebookShareUrl = (id: string): string => `${process.env.REACT_APP_API_URL}/client/prompts/${id}/facebook-share-url`
const defaultTitle: string = 'Great ChatGPT prompt I got for free at PromptPup: '

export const PromptShare: FC<PromptShareProps> = ({ promptContent}) => {
  const isMobile = useIsMobile();
  const iconSize = isMobile ? 32 : 42;
  const promptUrl = window.location.href ?? '';

  return (
    <PromptShareWrapper>
      <h3 className="fw-m pt-2 pb-2 text-center">Share this prompt.</h3>
      <div className="flex items-center mb-4">
        <div className="mr-4">
          <FacebookShareButton url={getFacebookShareUrl(promptContent.id)} quote={promptContent?.title ?? ''}>
            <FacebookIcon size={iconSize}/>
            <div className="fw-m p-2">Facebook</div>
          </FacebookShareButton>
        </div>
        <div className="mr-4">
          <LinkedinShareButton url={getFacebookShareUrl(promptContent.id)} title={promptContent?.title ?? ''}>
            <LinkedinIcon size={iconSize}/>
            <div className="fw-m p-2">Linkedin</div>
          </LinkedinShareButton>
        </div>
        <div>
          <TwitterShareButton url={promptUrl} title={defaultTitle + (promptContent?.title ?? '')}>
            <TwitterIcon size={iconSize}/>
            <div className="fw-m p-2">Twitter</div>
          </TwitterShareButton>
        </div>
      </div>

      <div className="flex items-center">
        <Input type="text"
               className="mr-2"
               style={{cursor: 'pointer', backgroundColor: '#eef2f7'}}
               value={promptUrl}
        />
        <Tooltip title="Copy URL">
          <Button type="default"
                  size="middle"
                  onClick={async () => await navigator.clipboard.writeText(promptUrl ?? '')}
          ><CopyOutlined/>
          </Button>
        </Tooltip>

      </div>

    </PromptShareWrapper>
  );
};
