import React, {FC} from 'react';
import Search from 'antd/es/input/Search';
import {SizeType} from 'antd/es/config-provider/SizeContext';


interface SearchBarProps {
  setQuery: (value: string) => void;
  placeholder?: string;
  size?: SizeType;
  query?: string;
  autoFocus?: boolean;
}

export const SearchBar: FC<SearchBarProps> = (
  {
    setQuery,
    placeholder = 'Search...',
    size = 'large',
    query = '',
    autoFocus = false
  }
) => {

  return (
    <Search size={size}
            autoFocus={autoFocus}
            style={{minWidth: '250px'}}
            value={query}
            placeholder={placeholder}
            onSearch={(value) => setQuery(value)}
            onChange={(ev) => setQuery(ev.target.value)}
            onMouseDown={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              (ev.target as HTMLInputElement)?.focus();
            }}
            onTouchStart={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              (ev.target as HTMLInputElement)?.focus();
            }}
    />
  );
};
