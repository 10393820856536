import {request} from './request';
import {ApiResponse, Article, Category, Prompt, Tag} from './index';
import {toQueryString} from '../../utils/query-string';
import {ApiParams} from '../interfaces/api-params';

interface CreatePrompt {
  prompt: string;
  title: string;
  description: string;
  category: string;
  tags: string[];
}

export const getPromptsApi = async (params: ApiParams): Promise<ApiResponse<Prompt>> => {
  return await request(`client/prompts?${toQueryString(params.search ? {search: params.search, type: params.type} : params)}`);
};

export const getPromptByIdApi = async (id: string): Promise<Prompt & {example: string}> => {
  return await request(`client/prompts/${id}`);
};

export const createPromptsApi = async (prompt: CreatePrompt): Promise<Prompt> => {
  return await request('client/prompts', {
    body: JSON.stringify(prompt),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'post',
  });
};

export const getTags = async (params: ApiParams): Promise<ApiResponse<Tag>> => {
  return await request(`client/tags?${toQueryString(params.search ? {search: params.search} : params)}`);
};

export const getCategories = async (params: ApiParams): Promise<ApiResponse<Category>> => {
  return await request(`client/categories?${toQueryString(params.search ? {search: params.search} : params)}`);
};

export const getArticles = async (params: ApiParams): Promise<ApiResponse<Article>> => {
  return await request(`client/articles?${toQueryString(params.search ? {search: params.search} : params)}`);
};

export const getArticleById = async (id: string): Promise<Article> => {
  return await request(`client/articles/${id}`);
};

