import type {FC} from 'react';
import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useUser} from '../common/hooks/useUser';
import {Loader} from './Loader';

interface AuthGuardProps {
}

const AuthGuard: FC<AuthGuardProps> = () => {
  const {user, loading} = useUser();

  if (loading) return <Loader/>;

  if (!user) {
    return <Navigate to="/"/>;
  }

  return <Outlet/>;
};

export default AuthGuard;
