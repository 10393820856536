import {useMutation, useQuery} from 'react-query';
import {createUserBookmarksApi, getUserBookmarksApi, promptUsagesByIdApi, removeUserBookmarkApi} from '../api/user-api';
import {useAtom} from 'jotai';
import {userProfile} from '../utils/atoms';

export const useUserBookmarksQuery = (isUserSignIn: boolean) =>
  useQuery({
    queryKey: 'user-bookmarks',
    queryFn: getUserBookmarksApi,
    enabled: isUserSignIn
  });

export const useUserBookmarksMutation = () => {
  const {mutate: createBookmark, isLoading: isLoadingCreate} = useMutation(createUserBookmarksApi);
  const {mutate: promptUsages, isLoading: isLoadingUsages} = useMutation(promptUsagesByIdApi);
  const {mutate: removeBookmark, isLoading: isLoadingRemove} = useMutation(removeUserBookmarkApi);
  return {
    createBookmark,
    removeBookmark,
    promptUsages,
    isLoading: isLoadingCreate || isLoadingRemove || isLoadingUsages
  };
};

export const useUser = () => {
  const [user] = useAtom(userProfile);
  return {user, loading: user === undefined};

};
