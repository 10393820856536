import {Button, Drawer} from 'antd';
import React, {FC, useEffect, useMemo, useState} from 'react';
import {MenuFoldOutlined, MenuUnfoldOutlined, PlusOutlined, UserOutlined} from '@ant-design/icons';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {UserFrame} from './UserFrame';
import {FirebaseModal} from './FirebaseModal';
import {FirebaseUser} from '../common/api';
import {defaultFilter, promptFilter, userSignIn} from '../common/utils/atoms';
import {useAtom} from 'jotai';
import {SpotlightSearch} from './SpotlightSearch';

const HeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  min-height: 60px;
  background-color: white;
  border-bottom: 1px solid #e3e8f7;
  z-index: 2;
`;

const LogoImageWrapper = styled.div`
  padding-left: 110px;
  @media (max-width: 768px) {
    padding-left: 80px;
    margin-right: 16px;
  }
`;
const LogoImage = styled.img`
  width: 100px;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  @media (max-width: 768px) {
    width: 80px;
    left: -10px;
  }
`;
const TextImage = styled.img`
  width: 150px;
  height: auto;
  @media (max-width: 768px) {
    width: 120px;
  }
`;

interface HeaderProps {
  isMobile: boolean;
  user: FirebaseUser | null;
}

export const Header: FC<HeaderProps> = ({isMobile, user}) => {
  const [openFirebaseModal, setOpenFirebaseModal] = useAtom(userSignIn);
  const setFilter = useAtom(promptFilter)[1];
  const navigate = useNavigate();
  const [openSpotlight, setOpenSpotlight] = useState(false);
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    function handleOpenSpotLight(ev: KeyboardEvent) {
      if (ev?.code === 'Escape' && openSpotlight) {
        setOpenSpotlight(false);
      } else if (ev?.key?.length === 1) {
        const activeElement = document.activeElement;
        if (!openSpotlight && activeElement && activeElement.tagName !== 'INPUT' && activeElement.tagName !== 'TEXTAREA') {
          setOpenSpotlight(true);
        }
      }

    }

    window.addEventListener('keydown', handleOpenSpotLight);
    return () => window.removeEventListener('keydown', handleOpenSpotLight);
  }, [openSpotlight, setOpenSpotlight]);

  const renderLogo = useMemo(() => (
    <LogoImageWrapper className="flex items-center mr-8 pointer h-full relative"
                      onClick={() => {
                        setFilter(defaultFilter);
                        navigate('/');
                      }}>
      <LogoImage
        src="/static/promptpup-hireslogo.png"
        alt="PromptPup"
      />
      <TextImage
        src="/static/wordmark.png"
        alt="PromptPup"
      />
    </LogoImageWrapper>
  ), [navigate, setFilter]);

  function handleNavigate(url: string): void {
    navigate(url);
    if (isMobile) {
      setCollapsed(true);
    }
  }

  const renderMobileView = () => (
    <>
      {renderLogo}
      <div className="flex">
        <Button type="default"
                size="middle"
                onClick={() => setCollapsed(!collapsed)}
        >
          {collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
        </Button>

        {user ? (
          <UserFrame user={user}/>
        ) : (
          <FirebaseModal open={openFirebaseModal} setOpen={(open) => setOpenFirebaseModal(open)}>
            <Button className="ml-2"
                    type="default"
                    icon={<UserOutlined/>}
                    size="middle"
                    shape="default"
                    onClick={() => setOpenFirebaseModal(true)}
            />
          </FirebaseModal>
        )}
      </div>

      <Drawer
        placement="bottom"
        height={'calc(100% - 81px)'}
        onClose={() => setCollapsed(true)}
        open={!collapsed}
        closable={false}
      >

        <Button className="bg-black mb-4 fw-m block w-full"
                size="middle"
                shape="round"
                type="primary"
                onClick={() => {
                  navigate('/create');
                  setCollapsed(true);
                }}
                icon={<PlusOutlined/>}
        >
          Create
        </Button>

        <Button className={`color-black fw-m block w-full ${window.location.pathname === '/' ? 'router-link-active' : ''}`}
                type="link"
                size="large"
                style={{borderRadius: 0}}
                onClick={() => {
                  setFilter(defaultFilter);
                  handleNavigate('/');
                }}
        >
          Home
        </Button>

        <Button className={`color-black fw-m block w-full ${window.location.pathname === '/text-prompts' ? 'router-link-active' : ''}`}
                type="link"
                size="large"
                style={{borderRadius: 0}}
                onClick={() => {
                  setFilter(defaultFilter);
                  handleNavigate('/text-prompts');
                }}
        >
          Text Prompts
        </Button>
        <Button className={`color-black fw-m block w-full ${window.location.pathname === '/image-prompts' ? 'router-link-active' : ''}`}
                type="link"
                size="large"
                style={{borderRadius: 0}}
                onClick={() => {
                  handleNavigate('/image-prompts');
                }}
        >
          Image Prompts
        </Button>

        <Button className={`color-black fw-m block w-full ${window.location.pathname === '/articles' ? 'router-link-active' : ''}`}
                type="link"
                style={{borderRadius: 0}}
                size="large"
                onClick={() => {
                  handleNavigate('/articles');
                }}
        >
          How To
        </Button>
      </Drawer>
    </>
  );

  const renderDesktopView = () => (
    <div className="flex no-wrap justify-between w-full items-center h-full">

      {renderLogo}

      <div className="flex justify-between items-center" style={{margin: '0 auto 0 16px'}}>

        <Button className={`color-black font-bold fw-m ${window.location.pathname === '/' ? 'router-link-active' : ''}`}
                type="link"
                size="large"
                style={{borderRadius: 0}}
                onClick={() => {
                  setFilter(defaultFilter);
                  handleNavigate('/');
                }}
        >
          Home
        </Button>

        <Button className={`color-black font-bold fw-m ${window.location.pathname === '/text-prompts' ? 'router-link-active' : ''}`}
                type="link"
                size="large"
                style={{borderRadius: 0}}
                onClick={() => {
                  setFilter(defaultFilter);
                  handleNavigate('/text-prompts');
                }}
        >
          Text Prompts
        </Button>

        <Button className={`color-black font-bold fw-m ${window.location.pathname === '/image-prompts' ? 'router-link-active' : ''}`}
                type="link"
                size="large"
                style={{borderRadius: 0}}
                onClick={() => handleNavigate('/image-prompts')}
        >
          Image Prompts
        </Button>

        <Button className={`color-black font-bold fw-m ${window.location.pathname === '/articles' ? 'router-link-active' : ''}`}
                type="link"
                style={{borderRadius: 0}}
                size="large"
                onClick={() => handleNavigate('/articles')}
        >
          How To
        </Button>
      </div>

      <div className="flex">
        <Button className="bg-black mr-4"
                size="middle"
                shape="round"
                type="primary"
                onClick={() => navigate('/create')}
                icon={<PlusOutlined/>}
        >
          Create
        </Button>

        {user ? (
          <UserFrame user={user}/>
        ) : (
          <FirebaseModal open={openFirebaseModal}
                         setOpen={(open) => setOpenFirebaseModal(open)}
          >
            <Button className="bg-black"
                    type="primary"
                    icon={<UserOutlined/>}
                    size="middle"
                    shape="round"
                    onClick={() => setOpenFirebaseModal(true)}
            >
              Login
            </Button>
          </FirebaseModal>
        )}

      </div>
    </div>
  );

  return (
    <HeaderWrapper>
      <FirebaseModal open={openFirebaseModal}
                     setOpen={(open) => setOpenFirebaseModal(open)}
      />
      {openSpotlight && <SpotlightSearch onClose={() => setOpenSpotlight(false)}/>}
      {isMobile ? renderMobileView() : renderDesktopView()}
    </HeaderWrapper>
  );
};
