import {useArticleByIdQuery, useArticlesQuery} from '../common/hooks/useArticles';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {Loader} from '../components/Loader';
import {useIsMobile} from '../common/hooks/useIsMobile';
import {Empty, Select} from 'antd';
import {useLocation} from 'react-router-dom';

const ArticlesWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  padding-top: 30px;
  @media (max-width: 768px) {
    padding-top: 10px;

    > div {
      display: block;
    }
  }
`;

const ArticlesList = styled.div`
  flex: 1;
  height: 100%;
  min-width: 250px;
  overflow: hidden;
  border-right: 1px solid #e3e8f7;
`;
const ArticlesListItem = styled.div`
  padding: 10px;
  cursor: pointer;
  font-weight: 500;

  &:hover, &.selected {
    font-weight: bold;
    background-color: #ff7502;
    color: white;
  }
`;

const CurrentArticle = styled.div`
  height: 100%;
  flex: 5;
  overflow: hidden;

  > div {
    overflow-y: auto;
    padding: 0 20px 20px 20px;
    height: 100%;

    * {
      word-break: break-word;
      white-space: normal;
      width: 100%;
      object-fit: contain
    }

    @media (max-width: 768px) {
      padding: 0 20px 20px 20px;
    }
  }
`;

const ArticlesWrapperContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 20px 0 60px;
  display: flex;
  justify-content: flex-start;
  gap: 40px;
`;
const ScrollableContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden auto;
`;

export const Articles = () => {
  const location = useLocation();
  const isMobile = useIsMobile();
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [articleId, setArticleId] = useState<string>('');
  const {data: articlesData, isLoading: isLoadingArticlesData} = useArticlesQuery();
  const {data: selectedArticle, isLoading: isLoadingArticle} = useArticleByIdQuery(articleId);

  useEffect(() => {
    if (!articlesData?.items?.length || !location) return;
    const currentArticleId = location.state;
    const currentArticle = articlesData.items.find((article) => article.id === currentArticleId);
    setArticleId(currentArticle?.id || articlesData.items[0]?.id);
  }, [articlesData, location]);

  const renderDesktopView = useMemo(() => (
    <>
      <ArticlesList>
        <ScrollableContainer>
          {articlesData?.items.map((article) => (
            <ArticlesListItem key={article.id}
                              className={`${articleId === article?.id ? 'selected' : ''}`}
                              onClick={() => {
                                setArticleId(article.id);
                                scrollContainerRef?.current?.scroll?.(0,0)
                              }}>{article.title}</ArticlesListItem>
          ))}
        </ScrollableContainer>
      </ArticlesList>

      <CurrentArticle>
        {isLoadingArticle ? (<Loader/>) : (
          !!selectedArticle?.text &&
          <ScrollableContainer  ref={scrollContainerRef} dangerouslySetInnerHTML={{__html: selectedArticle.text}}></ScrollableContainer>
        )}
      </CurrentArticle>

    </>
  ), [articlesData, articleId, setArticleId, isLoadingArticle, selectedArticle]);

  const renderMobileView = useMemo(() => (
    <>
      <div className="mb-2 ml-5 mr-5">
        <Select
          style={{width: '100%'}}
          value={articleId}
          size="large"
          onSelect={(id) => setArticleId(id)}
          showSearch={true}
          options={articlesData ? articlesData.items.map((item) => ({label: item.title, value: item.id})) : []}
        />
      </div>
      <CurrentArticle>
        {isLoadingArticle ? (<Loader/>) : (
          !!selectedArticle?.text &&
          <ScrollableContainer dangerouslySetInnerHTML={{__html: selectedArticle.text}}></ScrollableContainer>
        )}
      </CurrentArticle>
    </>
  ), [articlesData, articleId, setArticleId, isLoadingArticle, selectedArticle]);

  return (
    <ArticlesWrapper>
      <ArticlesWrapperContainer>
        {isLoadingArticlesData ? (
          <Loader/>) : (articlesData?.items.length ? (isMobile ? renderMobileView : renderDesktopView) : (
          <Empty className="fixed-center" description="Articles are empty"/>))}
      </ArticlesWrapperContainer>
    </ArticlesWrapper>
  );
};
