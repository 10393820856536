import {useInfiniteQuery, useQuery} from 'react-query';
import {getCategories, getTags} from '../api/client.api';
import {Order} from '../enums/order';

export const useTagsQuery = (
  search: string | undefined = undefined,
  size: number = 100,
  page: number = 0,
  order: Order = Order.Asc,
  sort: 'createdAt!!date' | 'createdAt!!date-active' = 'createdAt!!date'
) =>
  useInfiniteQuery({
    queryKey: ['tags', page],
    queryFn: ({pageParam}) => {
      if (page === 0 || pageParam?.hasNextPage) {
        return getTags({page: pageParam?.page || page, size, search, order, sort});
      }
    },
    getNextPageParam: (lastPage) => ({
      hasNextPage: Math.ceil(page * (lastPage as any)?.items?.length) < (lastPage as any)?.total
    }),
    queryHash: search,
    keepPreviousData: true,
  });

export const useCategoriesQuery = (
  search = undefined,
  size: number = 1000,
  page: number = 0,
  order: Order = Order.Asc,
  sort: 'createdAt!!date' | 'createdAt!!date-active' | 'index' = 'index'
) => useQuery('categories', () => getCategories({page, size, search, order, sort}));

