import {Button} from 'antd';
import React, {FC} from 'react';
import styled from 'styled-components';
import {CloseOutlined} from '@ant-design/icons';

interface TagItemProps {
  tag: string;
  onSelect?: (item: any, ev: any) => void;
  onClose?: () => void;
}

const CloseButton = styled.div`
  margin-left: 10px;
  display: inline-flex;
  color: red;
`;

export const TagItem: FC<TagItemProps> = ({tag, onSelect, onClose}) => {
  return (
    <Button type="link"
            className="flex items-center justify-center"
            style={{backgroundColor: 'rgba(210,223,247,0.7)'}}
            shape="round"
            size="middle"
            onClick={(ev) => onSelect?.(tag, ev)}
    >
      {tag}
      {!!onClose && <CloseButton onClick={(ev) => {
        ev.stopPropagation();
        ev.preventDefault();
        onClose();
      }}><CloseOutlined/></CloseButton>}
    </Button>


  );
};
